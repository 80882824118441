import type { PropsWithChildren } from 'react';

import * as Jotai from 'jotai';
import * as ToastPrimitives from '../primitives';

import { toastStore } from '../model/store';
import ToastMessageList from './toast-message-list';

export interface Props extends ToastPrimitives.ProviderProps {}

export function ToastMessageProvider({
  children,
  anchor,
  direction,
  duration = 5000,
  usePortal = true,
  maxLength = 5,
  size = 'md',
  ...props
}: PropsWithChildren<Props>) {
  return (
    <Jotai.Provider store={toastStore}>
      <ToastPrimitives.Provider
        swipeDirection={direction}
        duration={duration}
        anchor={anchor}
        direction={direction}
        usePortal={usePortal}
        size={size}
        {...props}
      >
        {children}

        <ToastMessageList maxLength={maxLength} size={size} />
        <ToastPrimitives.Viewport />
      </ToastPrimitives.Provider>
    </Jotai.Provider>
  );
}

export default ToastMessageProvider;
