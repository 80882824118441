import * as React from 'react';
import { TOAST_ANCHOR, TOAST_SIZE, TOAST_TYPE } from './contants';

/** Toast 표시 기준점 */
export type ToastAnchor = typeof TOAST_ANCHOR[keyof typeof TOAST_ANCHOR];

export type SwipeDirection = 
  | 'up' 
  | 'down' 
  | 'left' 
  | 'right'
  ;

/** @deprecated */
export type ToastDirection = 
  | 'up' 
  | 'down' 
  | 'left' 
  | 'right'
  ;

export type ToastTypes = typeof TOAST_TYPE[keyof typeof TOAST_TYPE];
export type ToastSizeType = typeof TOAST_SIZE[keyof typeof TOAST_SIZE];

export interface ToastItem {
  open: boolean;
  type: ToastTypes;
  contents: string | React.ReactElement;
  id: string;
}

export type ToastMessageActionFn = (contents: string | React.ReactElement) => void;
export interface ToastMessageActions {
  message: ToastMessageActionFn;
  warning: ToastMessageActionFn;
  alert: ToastMessageActionFn;
  info: ToastMessageActionFn;
}
