import { 
  ToastTypes, 
  ToastDirection, 
  ToastAnchor, 
  SwipeDirection 
} from './types';

export function generateUniqueId() {
  return '_' + Math.random().toString(36).substring(2, 9);
}

export function getToastStyles(type: ToastTypes) {
  switch (type) {
    case 'alert':
      return 'bg-[#ff0000] text-white';
    case 'warning':
      return 'bg-[#F4BA73] text-white';
    default:
      return 'bg-gray-500 text-white';
  }
}

export function dirToAnchor(dir: ToastDirection) {
  return {
    left: 'bottom-left',
    right: 'bottom-right',
    up: 'top',
    down: 'bottom',
  }[dir] as ToastAnchor | undefined;
}

export function getSwipeDirection(anchor: ToastAnchor) {
  return {
    top: 'up',
    'top-right': 'right',
    'top-left': 'up',
    bottom: 'down',
    'bottom-left': 'left',
    'bottom-right': 'right',
  }[anchor] as SwipeDirection | undefined;
}
