export const TOAST_ANCHOR = {
  TOP: 'top',
  TOP_RIGHT: 'top-right',
  TOP_LEFT: 'top-left',
  BOTTOM: 'bottom',
  BOTTOM_RIGHT: 'bottom-right',
  BOTTOM_LEFT: 'bottom-left',
} as const;

export const TOAST_SIZE = {
  SMALL: 'sm',
  MEDIUM: 'md',
  LARGE: 'lg',
} as const;

export const TOAST_TYPE = {
  MESSAGE: 'message',
  WARNING: 'warning',
  ALERT: 'alert',
  INFO: 'info',
} as const;
