import * as ToastPrimivites from '../../primitives';
import styles from './styles.module.css';

export interface Props {
  title?: string;
  description: string;
}

export function ToastMessageTemplateBasic({ title, description }: Props) {
  return (
    <div className={styles.ToastMessageBasic}>
      {title && (
        <ToastPrimivites.Title className={styles.title}>
          {title}
        </ToastPrimivites.Title>
      )}
      <ToastPrimivites.Description className={styles.description}>
        {description}
      </ToastPrimivites.Description>
    </div>
  );
}

export default ToastMessageTemplateBasic;
