import { ReactElement } from 'react';
import { clsx } from 'clsx';
import * as ToastPrimitives from '../primitives';
// import { IconX } from '@dop-ui/icons/react';
import { IconX } from '@tabler/icons-react';
import type { ToastTypes, ToastSizeType } from '../types';

import styles from './styles.module.css';

export interface Props {
  type: ToastTypes;
  description: string | ReactElement;
  open: boolean;
  size: ToastSizeType;
  onOpenChange: (open: boolean) => void;
  className?: string;
}

export function ToastMessageItem({
  type = 'message',
  open,
  onOpenChange,
  description,
  size,
  className,
}: Props) {
  return (
    <ToastPrimitives.Root
      className={clsx(styles.ToastMessage, className)}
      open={open}
      data-size={size}
      data-type={type}
      onOpenChange={onOpenChange}
    >
      <div className={styles.body}>{description}</div>
      <ToastPrimitives.Close className={styles.close}>
        <IconX stroke={1.5} />
      </ToastPrimitives.Close>
    </ToastPrimitives.Root>
  );
}

export default ToastMessageItem;
