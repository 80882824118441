import * as React from 'react';
import * as RadixPrimivites from '@radix-ui/react-toast';
import { Portal } from '@radix-ui/react-portal';
import { clsx } from 'clsx';
import { useToastContext } from './toast-provider';

import styles from './styles.module.css';

function Viewport({
  className,
  children,
  ...props
}: RadixPrimivites.ToastViewportProps) {
  const {
    toastContext: { usePortal, size, anchor },
  } = useToastContext();
  const Comp = usePortal ? Portal : React.Fragment;

  return (
    <Comp>
      <RadixPrimivites.Viewport
        {...props}
        className={clsx(styles.ToastViewport, className)}
        data-anchor={anchor}
        data-size={size}
      >
        {children}
      </RadixPrimivites.Viewport>
    </Comp>
  );
}

export default Viewport;
