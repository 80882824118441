import { fetchGraphQL } from '../../../shared/lib/fetch/client';
import * as userSessionQuery from '../model/user-session-query';
import type { LoginUserData, MeResponse, UserData } from '../types';

function userDataMapper(data: UserData): LoginUserData {
  const { GNB_PERSONAL_CONTROL: enableGnbControl = false } =
    data.company.companyAppConfig || {};

  return {
    id: data.id,
    name: data.name,
    loginId: data.loginId,
    email: data.email ?? 'no-email',
    gradeName: data.userInfo?.grade?.name ?? 'no-grade',
    departmentInfo: {
      id: data.mainDepartment?.id ?? 0,
      name: data.mainDepartment?.name ?? '',
      emailId: data.mainDepartment?.emailId,
      alias: data.mainDepartment?.alias,
      departmentPath: data.mainDepartment?.departmentPath,
    },
    departmentMemberList: [],
    status: data.status ?? 'no-status',
    companyUuid: data.company.uuid ?? 'no-company',
    companyId: data.company ? `${data.company.id}` : 'no-company',
    companyGroupId: data.company ? data.company.companyGroupId : 'no-company',
    siteUrl: (data.company && data.company.siteUrl) ?? 'no-company',
    profileImageUrl: data.userInfo.profileImageUrl ?? '',
    isBusinessPortalAccessible: data.isAccessibleBusinessPortal ?? false,
    isSettingAccessible: data.isAccessibleSettingPortal ?? false,
    isAccessibleArchivingService: data.isAccessibleArchivingService ?? false,
    locale: data.locale ?? '',
    enableGnbControl,
    accessibleAppCodeList: data.accessibleAppCodeList ?? [],
  };
}

export async function getSessionData() {
  const resp = await fetchGraphQL({
    query: userSessionQuery.Query,
    operationName: userSessionQuery.ID,
  });

  const data = (await resp.json()) as MeResponse;

  return userDataMapper(data.data.me);
}
