'use client';

import * as RadixPrimivites from '@radix-ui/react-toast';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
  // useEffect,
} from 'react';

import { TOAST_ANCHOR, TOAST_SIZE } from '../contants';
import { dirToAnchor, getSwipeDirection } from '../utils';
import type { ToastDirection, ToastAnchor, ToastSizeType } from '../types';

interface ToastConfigInfo {
  anchor?: ToastAnchor;
  duration?: number;
  usePortal?: boolean;
  size?: ToastSizeType;
}
export interface Props
  extends RadixPrimivites.ToastProviderProps,
    Omit<ToastConfigInfo, 'duration'> {
  toastWidth?: number;
  maxLength?: number;
  /** @deprecated */
  direction?: ToastDirection;
}

interface IToastContext {
  toastContext: ToastConfigInfo;
  setToastContext: Dispatch<SetStateAction<ToastConfigInfo>>;
}

const ToastContext = createContext<IToastContext>({
  toastContext: {
    anchor: TOAST_ANCHOR.BOTTOM_RIGHT,
    duration: 5000,
    usePortal: false,
    size: TOAST_SIZE.MEDIUM,
  },
  setToastContext: () => undefined,
});

export function Provider({
  children,
  anchor,
  direction,
  duration = 5000,
  usePortal = false,
  size = TOAST_SIZE.MEDIUM,
  // maxLength = 5,
  ...props
}: PropsWithChildren<Props>) {
  const anchorValue = (
    typeof anchor === 'undefined' && typeof direction !== 'undefined'
      ? dirToAnchor(direction)
      : anchor || TOAST_ANCHOR.BOTTOM_RIGHT
  ) as ToastAnchor;

  const [toastContext, setToastContext] = useState<ToastConfigInfo>({
    anchor: anchorValue,
    duration,
    usePortal,
    size,
  });

  //스토리북에서 direction, duration 변경시 적용을 위함.
  // useEffect(() => {
  //   setToastContext((prev) => ({ ...prev, anchor }));
  // }, [anchor]);

  // useEffect(() => {
  //   setToastContext((prev) => ({ ...prev, duration }));
  // }, [duration]);

  // useEffect(() => {
  //   setToastContext((prev) => ({ ...prev, size }));
  // }, [size]);

  // useEffect(() => {
  //   setToastContext((prev) => ({ ...prev, maxLength }));
  // }, [maxLength]);

  // useEffect(() => {
  //   setToastContext((prev) => ({ ...prev, usePortal }));
  // }, [usePortal]);

  const values = {
    toastContext,
    setToastContext,
  };

  return (
    <ToastContext value={values}>
      <RadixPrimivites.Provider
        swipeDirection={getSwipeDirection(anchorValue)}
        duration={duration}
        {...props}
      >
        {children}
      </RadixPrimivites.Provider>
    </ToastContext>
  );
}

export const useToastContext = () => useContext(ToastContext);

export default Provider;
